import { getGlobalParamsOptionsAsync } from '@/utils/common'

export const baseUpdateFormConfig = {
  labelWidth: '140px',
  itemStyle: {
    padding: '5px 20px'
  },
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'general.name',
      otherOptions: {
        maxlength: 100
      }
    },
    {
      field: 'tableId',
      type: 'select',
      label: 'Template',
      isResolveGlobalParams: true,
      options: [],
      handler: () =>
        getGlobalParamsOptionsAsync('paramTemplate', {
          isNeedPreFix: false
        })
    },
    {
      field: 'groupId',
      type: 'select',
      label: 'Template Group',
      isResolveGlobalParams: true,
      options: [],
      handler: () =>
        getGlobalParamsOptionsAsync('terminalParamGroup', {
          isNeedPreFix: false
        })
    },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      otherOptions: {
        maxlength: 200
      }
    }
  ],
  validateRules: {
    name: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ],
    tableId: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ],
    groupId: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ]
  }
}
